import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'flyAircraftPromotionSerialNumberError',
  standalone: true
})
export class AircraftPromotionSerialNumberErrorPipe implements PipeTransform {
  transform(value: ValidationErrors): string {
    if (value['tooShort'] === true || value['empty'] === true) {
      return 'The aircraft serial number must contain at least 3 characters.';
    } else if (value['tooLong'] === true) {
      return 'The aircraft serial number must not contain more than 18 characters.';
    } else {
      return '';
    }
  }
}
