import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Aircraft, AircraftPromotionRequest, AircraftService } from '@garmin-avcloud/avcloud-web-utils';
import { Observable, catchError, concatMap, forkJoin, map, of, tap } from 'rxjs';

import { Cart } from 'src/app/shared/models/cart/cart.model';
import { CartService } from 'src/app/shared/services/cart.service';
import { CartAircraft } from '../../../../../shared/models/cart/cart-aircraft';
import { nullAndUndefinedFilter } from '../../../../../shared/utilities/null-and-undefined-filter';

enum State {
  SerialNumbersRequired,
  Promoting,
  Error
}

@Component({
  selector: 'fly-cart-aircraft-promotion',
  styleUrl: './cart-aircraft-promotion.component.scss',
  templateUrl: './cart-aircraft-promotion.component.html',
  standalone: false
})
export class CartAircraftPromotionComponent implements OnInit {
  @Output() readonly errorOccurred = new EventEmitter<void>();
  @Output() readonly requestingSerialNumber = new EventEmitter<boolean>();
  @Output() readonly promotionCompleted = new EventEmitter<void>();

  private readonly aircraftService = inject(AircraftService);
  private readonly cartService = inject(CartService);

  readonly State = State;

  currentState: State = State.Promoting;
  aircraftTailsThatFailedToPromote: string[] = [];
  aircraftCompletionCounter: number = 1;
  numberOfAircraftToPromote: number = 0;
  failedAircraftPromotionDisplay: string | null = null;
  aircraftForPromotion: CartAircraft[] = [];
  aircraftRequiringSerialNumber: CartAircraft[] = [];
  aircraftUuidsWithNewSerialNumbers: string[] = [];
  serialNotEmpty: boolean = false;
  serialNumberFormControl: FormControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.minLength(3), Validators.maxLength(18)]
  });

  get isSerialNumberInvalid(): boolean {
    if (this.serialNumberFormControl.value.length < 3) {
      this.serialNumberFormControl.setErrors({ tooShort: true });
    } else if (this.serialNumberFormControl.value.length > 18) {
      this.serialNumberFormControl.setErrors({ tooLong: true });
    } else if (this.serialNumberFormControl.value.length == 0) {
      this.serialNumberFormControl.setErrors({ empty: true });
    } else {
      this.serialNumberFormControl.setErrors(null);
    }
    return this.serialNumberFormControl.invalid;
  }

  ngOnInit(): void {
    this.getAircraftForPromotion().subscribe((aircraftToPromote: Set<CartAircraft>) => {
      this.aircraftForPromotion = [...aircraftToPromote];
      this.aircraftRequiringSerialNumber = [...aircraftToPromote].filter(
        (aircraft: CartAircraft) => aircraft.serialNumber == null
      );
      if (this.aircraftRequiringSerialNumber.length > 0) {
        this.currentState = State.SerialNumbersRequired;
        this.requestingSerialNumber.emit(true);
      } else {
        this.promoteAircraft();
      }
    });
  }

  promoteAircraft(): void {
    of(new Set(this.aircraftForPromotion))
      .pipe(
        tap((aircraftToPromote: Set<CartAircraft>) => (this.numberOfAircraftToPromote = aircraftToPromote.size)),
        concatMap((aircraftUuidsToPromote: Set<CartAircraft>) => {
          if (aircraftUuidsToPromote.size === 0) {
            return of(null);
          }
          return forkJoin(
            Array.from(aircraftUuidsToPromote).map((cartAircraft: CartAircraft) => {
              // Promote the aircraft
              return this.promoteAircraft$(cartAircraft).pipe(
                // Add failed aircraft promotion tail numbers for later display
                catchError(() => {
                  this.aircraftTailsThatFailedToPromote.push(cartAircraft.id);
                  return of(null);
                }),
                // Increment the aircraft verified for display
                tap(() => (this.aircraftCompletionCounter += 1))
              );
            })
          );
        })
      )
      .subscribe({
        next: () => {
          // Error state if at least 1 aircraft failed to promote, otherwise complete state
          if (this.aircraftTailsThatFailedToPromote.length > 0) {
            this.failedAircraftPromotionDisplay = this.createFailedAircraftPromotionDisplay();
            this.currentState = State.Error;
            this.errorOccurred.emit();
          } else {
            this.promotionCompleted.emit();
          }
        },
        error: () => {
          this.currentState = State.Error;
          this.errorOccurred.emit();
        }
      });
  }

  private getAircraftForPromotion(): Observable<Set<CartAircraft>> {
    // Load the current cart since this is our source of truth for what bundles are in the cart
    return this.cartService.getCurrentCart().pipe(
      map((cart: Cart) => {
        const aircraftToPromote = new Set<CartAircraft>();
        const aircraftUuidsToPromote = new Array<string>();

        // Figure out which aircraft are associated with enablement devices that have not been promoted
        cart.enablementCart
          .map((cartItem) => cartItem.device.aircraft)
          .filter(nullAndUndefinedFilter)
          .filter((cartAircraft) => cartAircraft.masterAircraftUuid == null)
          .forEach((cartAircraft) => {
            if (!aircraftUuidsToPromote.includes(cartAircraft.aircraftUuid)) {
              aircraftUuidsToPromote.push(cartAircraft.aircraftUuid);
              aircraftToPromote.add(cartAircraft);
            }
          });

        // Figure out what aircraft are associated with an avdb that is associated to a device on an aircraft that has not been promoted
        cart.avdbCart
          .map((cartItem) => cartItem.device.aircraft)
          .filter(nullAndUndefinedFilter)
          .filter((cartAircraft) => cartAircraft.masterAircraftUuid == null)
          .forEach((cartAircraft) => {
            if (!aircraftUuidsToPromote.includes(cartAircraft.aircraftUuid)) {
              aircraftUuidsToPromote.push(cartAircraft.aircraftUuid);
              aircraftToPromote.add(cartAircraft);
            }
          });

        // Figure out which aircraft are associated with device bundles that are associated to devices on an aircraft that has not been promoted
        cart.avdbBundleCart
          .map((cartItem) => cartItem.device?.aircraft)
          .filter(nullAndUndefinedFilter)
          .filter((cartAircraft) => cartAircraft.masterAircraftUuid == null)
          .forEach((cartAircraft) => {
            if (!aircraftUuidsToPromote.includes(cartAircraft.aircraftUuid)) {
              aircraftUuidsToPromote.push(cartAircraft.aircraftUuid);
              aircraftToPromote.add(cartAircraft);
            }
          });

        // Figure out which aircraft bundles have v1 aircraft
        cart.avdbBundleCart
          .map((cartItem) => cartItem.aircraft)
          .filter(nullAndUndefinedFilter)
          .filter((cartAircraft) => cartAircraft.masterAircraftUuid == null)
          .forEach((cartAircraft) => {
            if (!aircraftUuidsToPromote.includes(cartAircraft.aircraftUuid)) {
              aircraftUuidsToPromote.push(cartAircraft.aircraftUuid);
              aircraftToPromote.add(cartAircraft);
            }
          });

        // Combine and convert the result to a set so we don't try to promote the same aircraft multiple times
        return aircraftToPromote;
      })
    );
  }

  private promoteAircraft$(aircraft: CartAircraft): Observable<Aircraft> {
    // TO-DO: [AVCLOUD-24556] Validate aircraft serial number before attempting promotion
    let promotionRequest = undefined;
    if (this.aircraftUuidsWithNewSerialNumbers.includes(aircraft.aircraftUuid)) {
      promotionRequest = {
        serialNumber: aircraft.serialNumber
      } as AircraftPromotionRequest;
    }
    return this.aircraftService.promoteAircraft(aircraft.aircraftUuid, promotionRequest);
  }

  private createFailedAircraftPromotionDisplay(): string {
    let display = this.aircraftTailsThatFailedToPromote.length > 1 ? 'tail numbers' : 'tail number';
    display = display.concat('<strong>');
    for (
      let failedAircraftIndex = 0;
      failedAircraftIndex < this.aircraftTailsThatFailedToPromote.length;
      failedAircraftIndex++
    ) {
      if (failedAircraftIndex === 0) {
        // The first item only needs a space separator
        display = display.concat(' ').concat(this.aircraftTailsThatFailedToPromote[failedAircraftIndex]);
      } else if (failedAircraftIndex === this.aircraftTailsThatFailedToPromote.length - 1) {
        // Last item in list needs and with separator (else if so a list with only 1 item doesn't hit this)
        display = display.concat(', and ').concat(this.aircraftTailsThatFailedToPromote[failedAircraftIndex]);
      } else {
        // All other items need a comma space separator
        display = display.concat(', ').concat(this.aircraftTailsThatFailedToPromote[failedAircraftIndex]);
      }
    }
    display = display.concat('</strong>');
    return display;
  }

  serialNumberEntered(): void {
    const aircraftToUpdate = this.aircraftRequiringSerialNumber.shift();
    const indexOfAircraftToUpdate = this.aircraftForPromotion.findIndex(
      (aircraft) => aircraft.aircraftUuid === aircraftToUpdate?.aircraftUuid
    );
    this.aircraftForPromotion[indexOfAircraftToUpdate].serialNumber = this.serialNumberFormControl.value;
    this.aircraftUuidsWithNewSerialNumbers.push(aircraftToUpdate!.aircraftUuid);

    if (this.aircraftRequiringSerialNumber.length === 0) {
      this.requestingSerialNumber.emit(false);
      this.currentState === State.Promoting;
      this.promoteAircraft();
    } else {
      this.serialNumberFormControl.setValue('');
    }
  }
}
