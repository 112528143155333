import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoverageDetailsMobileDeviceComponent } from '@garmin-avcloud/avcloud-fly-web-common/autorenewals';
import { ComponentsGlobalWebModule } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubscriptionManagementModule } from '../subscription-management/subscription-management.module';

import { BillingAccountSelectionComponent } from './cart/components/billing-account-selection/billing-account-selection.component';
import { CartAircraftPromotionComponent } from './cart/components/cart-aircraft-promotion/cart-aircraft-promotion.component';
import { CartItemAvdbComponent } from './cart/components/cart-item/cart-item-avdb/cart-item-avdb.component';
import { CartItemAvdbBundleComponent } from './cart/components/cart-item/cart-item-avdb-bundle/cart-item-avdb-bundle.component';
import { CartItemChangeStartDateModalComponent } from './cart/components/cart-item/cart-item-change-start-date-modal/cart-item-change-start-date-modal.component';
import { CartItemDiscountCodeComponent } from './cart/components/cart-item/cart-item-discount-code/cart-item-discount-code.component';
import { CartItemEnablementComponent } from './cart/components/cart-item/cart-item-enablement/cart-item-enablement.component';
import { CartItemGpComponent } from './cart/components/cart-item/cart-item-gp/cart-item-gp.component';
import { CartItemComponent } from './cart/components/cart-item/cart-item.component';
import { CartItemsAvdbBundlesComponent } from './cart/components/cart-item/cart-items-avdb-bundles/cart-items-avdb-bundles.component';
import { CartItemsEnablementsComponent } from './cart/components/cart-item/cart-items-enablements/cart-items-enablements.component';
import { CartSummaryComponent } from './cart/components/cart-summary/cart-summary.component';
import { CartValidationComponent } from './cart/components/cart-validation/cart-validation.component';
import { CheckoutComponent } from './cart/components/checkout/checkout.component';
import { CheckoutRedirectComponent } from './cart/components/checkout-redirect/checkout-redirect.component';
import { CoverageDetailsAvdbMultipleSeriesComponent } from './cart/components/coverage-details/coverage-details-avdb/coverage-details-avdb-multiple-series/coverage-details-avdb-multiple-series.component';
import { CoverageDetailsAvdbSingleSeriesComponent } from './cart/components/coverage-details/coverage-details-avdb/coverage-details-avdb-single-series/coverage-details-avdb-single-series.component';
import { CoverageDetailsAvdbComponent } from './cart/components/coverage-details/coverage-details-avdb/coverage-details-avdb.component';
import { CoverageDetailsAvdbBundleDeviceComponent } from './cart/components/coverage-details/coverage-details-avdb-bundle/coverage-details-avdb-bundle-device/coverage-details-avdb-bundle-device.component';
import { CoverageDetailsAvdbBundleInfoComponent } from './cart/components/coverage-details/coverage-details-avdb-bundle/coverage-details-avdb-bundle-info/coverage-details-avdb-bundle-info.component';
import { CoverageDetailsAvdbBundleComponent } from './cart/components/coverage-details/coverage-details-avdb-bundle/coverage-details-avdb-bundle.component';
import { PaymentDetailsComponent } from './cart/components/payment-details/payment-details.component';
import { CartComponent } from './cart/pages/cart/cart.component';
import { ConfirmationComponent } from './cart/pages/confirmation/confirmation.component';
import { PurchasingMenuComponent } from './cart/pages/purchasing-menu/purchasing-menu.component';
import { AircraftPromotionSerialNumberErrorPipe } from './cart/pipes/aircraft-promotion-serial-number-error.pipe';
import { ExpiringSubscriptionsComponent } from './expiring-subscriptions/pages/expiring-subscriptions.component';
import { PurchasingRoutingModule } from './purchasing-routing.module';

@NgModule({
  declarations: [
    CartComponent,
    CartItemAvdbBundleComponent,
    CartItemAvdbComponent,
    CartItemComponent,
    CartItemDiscountCodeComponent,
    CartItemEnablementComponent,
    CartItemGpComponent,
    CartItemsAvdbBundlesComponent,
    CartItemChangeStartDateModalComponent,
    CartItemsEnablementsComponent,
    CartAircraftPromotionComponent,
    CartSummaryComponent,
    ConfirmationComponent,
    CoverageDetailsAvdbBundleComponent,
    CoverageDetailsAvdbBundleDeviceComponent,
    CoverageDetailsAvdbBundleInfoComponent,
    CoverageDetailsAvdbComponent,
    CoverageDetailsAvdbMultipleSeriesComponent,
    CoverageDetailsAvdbSingleSeriesComponent,
    ExpiringSubscriptionsComponent,
    PaymentDetailsComponent,
    PurchasingMenuComponent,
    CheckoutComponent,
    CheckoutRedirectComponent,
    CartValidationComponent
  ],
  imports: [
    BillingAccountSelectionComponent,
    CommonModule,
    CoverageDetailsMobileDeviceComponent,
    ComponentsGlobalWebModule,
    SharedModule,
    PurchasingRoutingModule,
    FontAwesomeModule,
    SubscriptionManagementModule,
    AircraftPromotionSerialNumberErrorPipe
  ]
})
export class PurchasingModule {}
