@if (
  currentState === State.Promoting ||
  (currentState === State.SerialNumbersRequired && aircraftRequiringSerialNumber.length === 0)
) {
  @if (numberOfAircraftToPromote > 0) {
    <div class="text-container">
      Verifying {{ aircraftCompletionCounter }} of {{ numberOfAircraftToPromote }} aircraft in the cart
    </div>
  }
}

@if (currentState === State.SerialNumbersRequired && aircraftRequiringSerialNumber.length > 0) {
  @let aircraft = aircraftRequiringSerialNumber[0];
  <div class="text-container">
    <br />
    <p
      >In order to proceed to checkout, we need the following information about <strong>{{ aircraft?.id }}</strong
      >:</p
    >
    <br />
    <div class="center-form">
      <g-input
        class="form-field"
        with-button="true"
        [buttonTheme]="isSerialNumberInvalid ? 'disabled' : 'dark'"
        label="Aircraft {{ aircraft?.id }}'s Serial Number"
        label-for="serialNumber"
        required="true"
        [error]="serialNumberFormControl.errors != null && !serialNumberFormControl.pristine"
        (g-click-append)="serialNumberEntered()">
        <input
          type="text"
          name="serialNumber"
          id="serialNumber"
          [formControl]="serialNumberFormControl"
          (keydown.enter)="isSerialNumberInvalid ? null : serialNumberEntered()" />
      </g-input>
      @if (serialNumberFormControl.errors != null && !serialNumberFormControl.pristine) {
        <g-error
          id="name-error-text"
          class="form-field">
          {{ serialNumberFormControl.errors | flyAircraftPromotionSerialNumberError }}
        </g-error>
      }
    </div>
  </div>
}

@if (currentState === State.Error) {
  @if (aircraftTailsThatFailedToPromote.length > 0) {
    <div class="text-container">
      <p class="error-message"
        >We were not able to verify your aircraft with the
        <span [innerHTML]="failedAircraftPromotionDisplay"></span>.</p
      >
      <p class="error-message"
        >Please verify that your aircraft tail number, serial number, and device serial numbers are correct.</p
      >
      <p>If errors persist, contact <a [routerLink]="['/aviation-support']">Aviation Product Support</a>.</p>
    </div>
  }
  @if (aircraftTailsThatFailedToPromote.length === 0) {
    <div class="text-container">
      <p class="error-message">An error occurred while preparing your cart. Please refresh the page and try again.</p>
      <p>If errors persist, contact <a [routerLink]="['/aviation-support']">Aviation Product Support</a>.</p>
    </div>
  }
}
