<g-flex class="container">
  <g-flex class="cart-item">
    <g-flex class="content-section">
      @if (
        showBillingAccountSelector &&
        (currentState === State.Loading ||
          currentState === State.AccountLoadError ||
          currentState === State.Loaded ||
          currentState === State.CartItemsValidationError)
      ) {
        <g-flex class="status">
          @if (currentState === State.Loading) {
            <div class="content-loading-skeleton">
              <g-skeleton></g-skeleton>
            </div>
          }
          @if (currentState === State.Loaded || currentState === State.CartItemsValidationError) {
            <fly-billing-account-selection
              [accounts]="accounts"
              [formGroup]="cartForm">
            </fly-billing-account-selection>
          }
          @if (currentState === State.AccountLoadError) {
            <g-error [innerHTML]="getErrorMessage(currentState)"></g-error>
          }
        </g-flex>
        <g-flex class="subtotal-section">
          @if (currentState === State.Loading) {
            <div class="subtotal-loading-skeleton">
              <g-skeleton></g-skeleton>
            </div>
          }
          @if (currentState === State.Loaded || currentState === State.CartItemsValidationError) {
            <span><strong>Subtotal</strong></span>
            @if (subtotal != null && subtotal.error == null) {
              <g-price [originalPrice]="subtotal.sum.formattedValue"></g-price>
            }
          }
        </g-flex>

        @if (currentState === State.Loading) {
          <div class="loading-skeleton">
            <g-skeleton></g-skeleton>
          </div>
        }
        @if (currentState === State.Loaded || currentState === State.CartItemsValidationError) {
          <p class="shipping-text">Shipping and tax calculated in checkout</p>
        }

        <g-flex class="discount-section">
          @if (currentState === State.Loading) {
            <div class="discount-loading-skeleton">
              <g-skeleton> </g-skeleton>
            </div>
          }
          @if (currentState === State.Loaded || currentState === State.CartItemsValidationError) {
            <label for="discountCode">
              <span>
                <strong>Discount Code</strong>
              </span>
            </label>

            @switch (addingDiscountCodeCartItem) {
              @case (true) {
                <g-loader
                  class="loader"
                  type="dark">
                </g-loader>
              }
              @case (false) {
                <form
                  [formGroup]="codeForm"
                  (ngSubmit)="addDiscountCodeToCart($event)">
                  <g-flex class="code-input-section">
                    <g-input
                      class="code-input-field"
                      label="discountCode"
                      label-for="discountCode"
                      hideLabel="true">
                      <input
                        class="discount-code-input"
                        id="discountCode"
                        name="discountCode"
                        type="text"
                        [formControlName]="'discountCode'" />
                    </g-input>
                    <g-button
                      class="submit-btn"
                      [disabled]="
                        !(currentState === State.Loaded || currentState === State.CartItemsValidationError) ||
                        codeForm.invalid
                      "
                      size="medium"
                      theme="light"
                      type="submit">
                      APPLY
                    </g-button>
                  </g-flex>
                  @if (codeForm.controls.discountCode.hasError('discountCodeInCartAlready')) {
                    <g-error> Discount code is in the cart already. </g-error>
                  }
                </form>
              }
            }
          }
        </g-flex>
        @if (this.hasPendingBillingAccount && currentState !== State.AccountLoadError) {
          <g-loader
            class="pending-bill-acc-loader"
            type="dark"></g-loader>
          <span>Hold short. We're finalizing your billing account. </span>
        } @else if (
          (currentState === State.Loading ||
            currentState === State.Loaded ||
            currentState === State.CartItemsValidationError) &&
          !checkoutDisabled
        ) {
          <g-button
            class="checkout-btn"
            [disabled]="!(currentState === State.Loaded || currentState === State.CartItemsValidationError)"
            size="medium"
            (click)="onCheckout()"
            theme="{{
              !(currentState === State.Loaded) ||
              cartForm.invalid ||
              addingDiscountCodeCartItem ||
              numberOfCartItemsBeingRemoved !== 0 ||
              (userConfig.isFleet && fleetUserErrorMessage() != null) ||
              checkoutDisabled
                ? 'disabled'
                : 'candy-blue'
            }}">
            CHECK OUT
          </g-button>
          @if (userConfig.isFleet) {
            <p>
              @if (fleetUserErrorMessage() != null) {
                <g-error class="font-size-unset">{{ fleetUserErrorMessage() }}</g-error>
              } @else {
                When you click Check Out you will be granted the items in your cart immediately and billed later
                according to your fleet terms with Garmin.
              }
            </p>
          }
        }
      }
      @if (checkoutDisabled) {
        <g-error>
          <p>
            Purchasing is currently disabled due to scheduled maintenance. Please visit the
            <a href="https://fly.garmin.com/fly-garmin/">fly.garmin.com</a> home page for the latest status update.
          </p>
        </g-error>
      }
      @if (currentState !== State.AccountLoadError && currentState != null) {
        <g-error>
          <p [innerHTML]="getErrorMessage(currentState)"> </p>
        </g-error>
      }
    </g-flex>
  </g-flex>
</g-flex>
